const initState = () => ({
  users: []
})

export const state = initState

export const getters = {
  getUsers: (state) => state.users,
}

export const mutations = {
  setUsers(state, {users}) {
    state.users = users
  }
}

export const actions = {

  async getSalesRepsForFiltering({commit}, {filters}){
    let salesReps = []
    let url = `webpos/users/sales-reps?${new URLSearchParams(filters).toString()}`
    try{
      const response = await this.$axios.$get(url)
      if(response.status > 0 || response.vResult.errorMessage.length > 0){
        this.$toast.error("Error while getting the sales reps for filter.")
        return []
      }
      salesReps = response.value.map((user) => {
        return {
          value: user.id,
          text: user.fullName,
        }
      })
    }
    catch(e){
      this.$toast.error("Error while getting the sales reps for filter.")
    }
    return salesReps
  },
  async getAllUsers({commit}) {
      let users = []
      try {
        const response = await this.$axios.$get('/user/all-users')
        if (!response || !response.apiMessages || response.apiMessages.hasErrors) {
          this.$toast.error('Error while getting users')
          return []
        }
        users = response.value
        commit('setUsers', { users })
      } catch {
        this.$toast.error('Error while getting users')
      }
      return users
  }}
