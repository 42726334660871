import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  salesCommissions: [],
  commissionPercentage: 0,

})

export const state = initState

export const getters = {
}
export const mutations = {
  setNewSalesCommissions(state, salesCommissions) {
    state.salesCommissions = salesCommissions
  },
  addNewSalesCommission(state, salesCommission) {
    state.salesCommissions.push(salesCommission)
  },
  setCommissionPercentage(state, commissionPercentage) {
    state.commissionPercentage = commissionPercentage
  }
}

export const actions = {

}
