import {mapFilters} from "@/utils/helpers";
const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {

  async fetchInvoices({commit, state}, {pagingInfo, filters}){
    let invoices = [];

    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);

      const result = await this.$axios.get( `/webpos/invoice?${new URLSearchParams(filter).toString()}`);

      if(result?.data && !result.data.hasErrors){
        invoices = result.data;
      }
      else{
        this.$toast.error('Error while getting invoices')
      }
    }
    catch{
      this.$toast.error('Error while getting invoices')
    }
    return invoices;
  },

  async fetchInvoiceItems({commit, state}, {invoiceID}){
    let invoices = [];

    try{
      const result = await this.$axios.get( `/webpos/invoice/${invoiceID}/items`);

      if(result?.data?.apiMessages.hasErrors){
        this.$toast.error('Error while getting invoice items')
      }
      else if(result.data?.value?.length){
        invoices = result.data.value;
      }
    }
    catch{
      this.$toast.error('Error while getting invoice items')
    }
    return invoices;
  },


  async updateInvoiceNotes({commit, state}, {invoice}){
    try{
      await this.$axios.post( `/webpos/invoice/${invoice.id}/notes`, invoice);
      this.$toast.success("Invoice notes updated successfully");
      return true;
    }
    catch (error){
      this.$toast.error('Error while updating invoice notes')
      return false;
    }

  },

}
