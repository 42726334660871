import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46ee2518 = () => interopDefault(import('..\\pages\\catalog\\index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _1e832058 = () => interopDefault(import('..\\pages\\customer-credits\\index.vue' /* webpackChunkName: "pages/customer-credits/index" */))
const _7eabfaa3 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _48539dba = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _0f312209 = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _2c1943ca = () => interopDefault(import('..\\pages\\my-profile\\index.vue' /* webpackChunkName: "pages/my-profile/index" */))
const _18d4330f = () => interopDefault(import('..\\pages\\new-sale\\index.vue' /* webpackChunkName: "pages/new-sale/index" */))
const _1c2347ef = () => interopDefault(import('..\\pages\\order-detail.vue' /* webpackChunkName: "pages/order-detail" */))
const _1c1de21e = () => interopDefault(import('..\\pages\\orders\\index.vue' /* webpackChunkName: "pages/orders/index" */))
const _15bf4e96 = () => interopDefault(import('..\\pages\\payments\\index.vue' /* webpackChunkName: "pages/payments/index" */))
const _7613ed40 = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _7c20609c = () => interopDefault(import('..\\pages\\reports\\index.vue' /* webpackChunkName: "pages/reports/index" */))
const _9a07ce04 = () => interopDefault(import('..\\pages\\wishlist\\index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _c0dc42f4 = () => interopDefault(import('..\\pages\\catalog\\searched-catalogs.vue' /* webpackChunkName: "pages/catalog/searched-catalogs" */))
const _25ca9c12 = () => interopDefault(import('..\\pages\\cms\\components\\index.vue' /* webpackChunkName: "pages/cms/components/index" */))
const _4da3c10e = () => interopDefault(import('..\\pages\\cms\\contents\\index.vue' /* webpackChunkName: "pages/cms/contents/index" */))
const _16c9c1da = () => interopDefault(import('..\\pages\\configuration\\admin-settings\\index.vue' /* webpackChunkName: "pages/configuration/admin-settings/index" */))
const _736011c8 = () => interopDefault(import('..\\pages\\configuration\\integrations\\index.vue' /* webpackChunkName: "pages/configuration/integrations/index" */))
const _26814134 = () => interopDefault(import('..\\pages\\crm\\customer-list\\index.vue' /* webpackChunkName: "pages/crm/customer-list/index" */))
const _6378498e = () => interopDefault(import('..\\pages\\customer-credits\\create-new.vue' /* webpackChunkName: "pages/customer-credits/create-new" */))
const _8c09f30e = () => interopDefault(import('..\\pages\\finance\\invoice-list\\index.vue' /* webpackChunkName: "pages/finance/invoice-list/index" */))
const _0136c9f4 = () => interopDefault(import('..\\pages\\reports\\customer-balances.vue' /* webpackChunkName: "pages/reports/customer-balances" */))
const _2737df72 = () => interopDefault(import('..\\pages\\reports\\customer-statements-dialog.vue' /* webpackChunkName: "pages/reports/customer-statements-dialog" */))
const _16f04448 = () => interopDefault(import('..\\pages\\reports\\events-report.vue' /* webpackChunkName: "pages/reports/events-report" */))
const _c3090380 = () => interopDefault(import('..\\pages\\reports\\layaways-report.vue' /* webpackChunkName: "pages/reports/layaways-report" */))
const _652fca44 = () => interopDefault(import('..\\pages\\reports\\sales-reports.vue' /* webpackChunkName: "pages/reports/sales-reports" */))
const _7fe87c91 = () => interopDefault(import('..\\pages\\reports\\store-transactions.vue' /* webpackChunkName: "pages/reports/store-transactions" */))
const _bab8eb6e = () => interopDefault(import('..\\pages\\reports\\wishlist-items-report.vue' /* webpackChunkName: "pages/reports/wishlist-items-report" */))
const _b836b65a = () => interopDefault(import('..\\pages\\services\\custom-order\\index.vue' /* webpackChunkName: "pages/services/custom-order/index" */))
const _06be54c4 = () => interopDefault(import('..\\pages\\services\\layaways\\index.vue' /* webpackChunkName: "pages/services/layaways/index" */))
const _e715f50c = () => interopDefault(import('..\\pages\\services\\repair-center\\index.vue' /* webpackChunkName: "pages/services/repair-center/index" */))
const _0dc07c9b = () => interopDefault(import('..\\pages\\services\\trade-in-center\\index.vue' /* webpackChunkName: "pages/services/trade-in-center/index" */))
const _37cfda02 = () => interopDefault(import('..\\pages\\crm\\customer-list\\customer-detail.vue' /* webpackChunkName: "pages/crm/customer-list/customer-detail" */))
const _aa6cc31e = () => interopDefault(import('..\\pages\\services\\custom-order\\create-new-custom-order.vue' /* webpackChunkName: "pages/services/custom-order/create-new-custom-order" */))
const _d38ededc = () => interopDefault(import('..\\pages\\services\\layaways\\create-new-layaway.vue' /* webpackChunkName: "pages/services/layaways/create-new-layaway" */))
const _4fca3054 = () => interopDefault(import('..\\pages\\services\\repair-center\\create-new-repair-ticket.vue' /* webpackChunkName: "pages/services/repair-center/create-new-repair-ticket" */))
const _fa105c16 = () => interopDefault(import('..\\pages\\services\\trade-in-center\\create-new-trade-in.vue' /* webpackChunkName: "pages/services/trade-in-center/create-new-trade-in" */))
const _6ffb80a9 = () => interopDefault(import('..\\pages\\cms\\components\\_componentId.vue' /* webpackChunkName: "pages/cms/components/_componentId" */))
const _194095e9 = () => interopDefault(import('..\\pages\\cms\\contents\\_contentId.vue' /* webpackChunkName: "pages/cms/contents/_contentId" */))
const _da9a310c = () => interopDefault(import('..\\pages\\services\\custom-order\\_customOrderID\\index.vue' /* webpackChunkName: "pages/services/custom-order/_customOrderID/index" */))
const _1af1495f = () => interopDefault(import('..\\pages\\catalog\\_catalogID\\products\\index.vue' /* webpackChunkName: "pages/catalog/_catalogID/products/index" */))
const _1eb9e9a0 = () => interopDefault(import('..\\pages\\catalog\\_catalogID\\products\\_productID\\items\\index.vue' /* webpackChunkName: "pages/catalog/_catalogID/products/_productID/items/index" */))
const _60d709c7 = () => interopDefault(import('..\\pages\\catalog\\_catalogID\\products\\_productID\\items\\_itemID\\index.vue' /* webpackChunkName: "pages/catalog/_catalogID/products/_productID/items/_itemID/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _46ee2518,
    name: "catalog___en"
  }, {
    path: "/customer-credits",
    component: _1e832058,
    name: "customer-credits___en"
  }, {
    path: "/fr",
    component: _7eabfaa3,
    name: "index___fr"
  }, {
    path: "/login",
    component: _48539dba,
    name: "login___en"
  }, {
    path: "/logout",
    component: _0f312209,
    name: "logout___en"
  }, {
    path: "/my-profile",
    component: _2c1943ca,
    name: "my-profile___en"
  }, {
    path: "/new-sale",
    component: _18d4330f,
    name: "new-sale___en"
  }, {
    path: "/order-detail",
    component: _1c2347ef,
    name: "order-detail___en"
  }, {
    path: "/orders",
    component: _1c1de21e,
    name: "orders___en"
  }, {
    path: "/payments",
    component: _15bf4e96,
    name: "payments___en"
  }, {
    path: "/register",
    component: _7613ed40,
    name: "register___en"
  }, {
    path: "/reports",
    component: _7c20609c,
    name: "reports___en"
  }, {
    path: "/wishlist",
    component: _9a07ce04,
    name: "wishlist___en"
  }, {
    path: "/catalog/searched-catalogs",
    component: _c0dc42f4,
    name: "catalog-searched-catalogs___en"
  }, {
    path: "/cms/components",
    component: _25ca9c12,
    name: "cms-components___en"
  }, {
    path: "/cms/contents",
    component: _4da3c10e,
    name: "cms-contents___en"
  }, {
    path: "/configuration/admin-settings",
    component: _16c9c1da,
    name: "configuration-admin-settings___en"
  }, {
    path: "/configuration/integrations",
    component: _736011c8,
    name: "configuration-integrations___en"
  }, {
    path: "/crm/customer-list",
    component: _26814134,
    name: "crm-customer-list___en"
  }, {
    path: "/customer-credits/create-new",
    component: _6378498e,
    name: "customer-credits-create-new___en"
  }, {
    path: "/finance/invoice-list",
    component: _8c09f30e,
    name: "finance-invoice-list___en"
  }, {
    path: "/fr/catalog",
    component: _46ee2518,
    name: "catalog___fr"
  }, {
    path: "/fr/customer-credits",
    component: _1e832058,
    name: "customer-credits___fr"
  }, {
    path: "/fr/login",
    component: _48539dba,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _0f312209,
    name: "logout___fr"
  }, {
    path: "/fr/my-profile",
    component: _2c1943ca,
    name: "my-profile___fr"
  }, {
    path: "/fr/new-sale",
    component: _18d4330f,
    name: "new-sale___fr"
  }, {
    path: "/fr/order-detail",
    component: _1c2347ef,
    name: "order-detail___fr"
  }, {
    path: "/fr/orders",
    component: _1c1de21e,
    name: "orders___fr"
  }, {
    path: "/fr/payments",
    component: _15bf4e96,
    name: "payments___fr"
  }, {
    path: "/fr/register",
    component: _7613ed40,
    name: "register___fr"
  }, {
    path: "/fr/reports",
    component: _7c20609c,
    name: "reports___fr"
  }, {
    path: "/fr/wishlist",
    component: _9a07ce04,
    name: "wishlist___fr"
  }, {
    path: "/reports/customer-balances",
    component: _0136c9f4,
    name: "reports-customer-balances___en"
  }, {
    path: "/reports/customer-statements-dialog",
    component: _2737df72,
    name: "reports-customer-statements-dialog___en"
  }, {
    path: "/reports/events-report",
    component: _16f04448,
    name: "reports-events-report___en"
  }, {
    path: "/reports/layaways-report",
    component: _c3090380,
    name: "reports-layaways-report___en"
  }, {
    path: "/reports/sales-reports",
    component: _652fca44,
    name: "reports-sales-reports___en"
  }, {
    path: "/reports/store-transactions",
    component: _7fe87c91,
    name: "reports-store-transactions___en"
  }, {
    path: "/reports/wishlist-items-report",
    component: _bab8eb6e,
    name: "reports-wishlist-items-report___en"
  }, {
    path: "/services/custom-order",
    component: _b836b65a,
    name: "services-custom-order___en"
  }, {
    path: "/services/layaways",
    component: _06be54c4,
    name: "services-layaways___en"
  }, {
    path: "/services/repair-center",
    component: _e715f50c,
    name: "services-repair-center___en"
  }, {
    path: "/services/trade-in-center",
    component: _0dc07c9b,
    name: "services-trade-in-center___en"
  }, {
    path: "/crm/customer-list/customer-detail",
    component: _37cfda02,
    name: "crm-customer-list-customer-detail___en"
  }, {
    path: "/fr/catalog/searched-catalogs",
    component: _c0dc42f4,
    name: "catalog-searched-catalogs___fr"
  }, {
    path: "/fr/cms/components",
    component: _25ca9c12,
    name: "cms-components___fr"
  }, {
    path: "/fr/cms/contents",
    component: _4da3c10e,
    name: "cms-contents___fr"
  }, {
    path: "/fr/configuration/admin-settings",
    component: _16c9c1da,
    name: "configuration-admin-settings___fr"
  }, {
    path: "/fr/configuration/integrations",
    component: _736011c8,
    name: "configuration-integrations___fr"
  }, {
    path: "/fr/crm/customer-list",
    component: _26814134,
    name: "crm-customer-list___fr"
  }, {
    path: "/fr/customer-credits/create-new",
    component: _6378498e,
    name: "customer-credits-create-new___fr"
  }, {
    path: "/fr/finance/invoice-list",
    component: _8c09f30e,
    name: "finance-invoice-list___fr"
  }, {
    path: "/fr/reports/customer-balances",
    component: _0136c9f4,
    name: "reports-customer-balances___fr"
  }, {
    path: "/fr/reports/customer-statements-dialog",
    component: _2737df72,
    name: "reports-customer-statements-dialog___fr"
  }, {
    path: "/fr/reports/events-report",
    component: _16f04448,
    name: "reports-events-report___fr"
  }, {
    path: "/fr/reports/layaways-report",
    component: _c3090380,
    name: "reports-layaways-report___fr"
  }, {
    path: "/fr/reports/sales-reports",
    component: _652fca44,
    name: "reports-sales-reports___fr"
  }, {
    path: "/fr/reports/store-transactions",
    component: _7fe87c91,
    name: "reports-store-transactions___fr"
  }, {
    path: "/fr/reports/wishlist-items-report",
    component: _bab8eb6e,
    name: "reports-wishlist-items-report___fr"
  }, {
    path: "/fr/services/custom-order",
    component: _b836b65a,
    name: "services-custom-order___fr"
  }, {
    path: "/fr/services/layaways",
    component: _06be54c4,
    name: "services-layaways___fr"
  }, {
    path: "/fr/services/repair-center",
    component: _e715f50c,
    name: "services-repair-center___fr"
  }, {
    path: "/fr/services/trade-in-center",
    component: _0dc07c9b,
    name: "services-trade-in-center___fr"
  }, {
    path: "/services/custom-order/create-new-custom-order",
    component: _aa6cc31e,
    name: "services-custom-order-create-new-custom-order___en"
  }, {
    path: "/services/layaways/create-new-layaway",
    component: _d38ededc,
    name: "services-layaways-create-new-layaway___en"
  }, {
    path: "/services/repair-center/create-new-repair-ticket",
    component: _4fca3054,
    name: "services-repair-center-create-new-repair-ticket___en"
  }, {
    path: "/services/trade-in-center/create-new-trade-in",
    component: _fa105c16,
    name: "services-trade-in-center-create-new-trade-in___en"
  }, {
    path: "/fr/crm/customer-list/customer-detail",
    component: _37cfda02,
    name: "crm-customer-list-customer-detail___fr"
  }, {
    path: "/fr/services/custom-order/create-new-custom-order",
    component: _aa6cc31e,
    name: "services-custom-order-create-new-custom-order___fr"
  }, {
    path: "/fr/services/layaways/create-new-layaway",
    component: _d38ededc,
    name: "services-layaways-create-new-layaway___fr"
  }, {
    path: "/fr/services/repair-center/create-new-repair-ticket",
    component: _4fca3054,
    name: "services-repair-center-create-new-repair-ticket___fr"
  }, {
    path: "/fr/services/trade-in-center/create-new-trade-in",
    component: _fa105c16,
    name: "services-trade-in-center-create-new-trade-in___fr"
  }, {
    path: "/fr/cms/components/:componentId",
    component: _6ffb80a9,
    name: "cms-components-componentId___fr"
  }, {
    path: "/fr/cms/contents/:contentId",
    component: _194095e9,
    name: "cms-contents-contentId___fr"
  }, {
    path: "/fr/services/custom-order/:customOrderID",
    component: _da9a310c,
    name: "services-custom-order-customOrderID___fr"
  }, {
    path: "/cms/components/:componentId",
    component: _6ffb80a9,
    name: "cms-components-componentId___en"
  }, {
    path: "/cms/contents/:contentId",
    component: _194095e9,
    name: "cms-contents-contentId___en"
  }, {
    path: "/services/custom-order/:customOrderID",
    component: _da9a310c,
    name: "services-custom-order-customOrderID___en"
  }, {
    path: "/fr/catalog/:catalogID/products",
    component: _1af1495f,
    name: "catalog-catalogID-products___fr"
  }, {
    path: "/fr/catalog/:catalogID/products/:productID/items",
    component: _1eb9e9a0,
    name: "catalog-catalogID-products-productID-items___fr"
  }, {
    path: "/fr/catalog/:catalogID/products/:productID/items/:itemID",
    component: _60d709c7,
    name: "catalog-catalogID-products-productID-items-itemID___fr"
  }, {
    path: "/catalog/:catalogID/products",
    component: _1af1495f,
    name: "catalog-catalogID-products___en"
  }, {
    path: "/catalog/:catalogID/products/:productID/items",
    component: _1eb9e9a0,
    name: "catalog-catalogID-products-productID-items___en"
  }, {
    path: "/catalog/:catalogID/products/:productID/items/:itemID",
    component: _60d709c7,
    name: "catalog-catalogID-products-productID-items-itemID___en"
  }, {
    path: "/",
    component: _7eabfaa3,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
