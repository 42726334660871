const initState = () => ({
  items: [],
  cartItemCount: 0,
})

export const state = initState

export const getters = {
}

export const mutations = {
  removeItem(state, {item}){
    state.items = state.items.filter(shoppingCartItem => shoppingCartItem.id !== item.id)

  },
  resetItems(state){
    state.items = []
    state.cartItemCount = 0
  },
  updateQuantity(state, {modifiedItem, useOldQuantity = false}){
    let shoppingCartItem  = state.items.find(shoppingCartItem =>
      shoppingCartItem.cartID === modifiedItem.cartID && shoppingCartItem.id === modifiedItem.id)

    if(useOldQuantity){
      shoppingCartItem.itemTypeData.quantity = modifiedItem.itemTypeData.oldQuantity
      shoppingCartItem.itemTypeData.quantity2 = modifiedItem.itemTypeData.oldQuantity2
      return;
    }
    if(modifiedItem){
      const oldItem = JSON.parse(JSON.stringify(shoppingCartItem))
      shoppingCartItem.itemTypeData = modifiedItem.itemTypeData
      modifiedItem.itemTypeData.oldQuantity = oldItem.itemTypeData.quantity
      modifiedItem.itemTypeData.oldQuantity2 = oldItem.itemTypeData.quantity2
    }
  },
  setAttributes(state, {attributes}) {
    state.attributes = attributes
  },
  updateCartItemCount(state){
    state.cartItemCount = 0
    state.items.forEach(x => state.cartItemCount += x.itemTypeData.quantity)
  },
  loadShoppingCart(state,{shoppingCart}){
    shoppingCart.items.forEach(x => state.items.push(x))
  },
  updateItemDiscount(state,{item, discount, markupType}){
    let selectedItem = state.items.find(i => i.id === item.id)
    if(selectedItem?.itemTypeData){
      selectedItem.itemTypeData.discount = discount
      selectedItem.itemTypeData.markupType = markupType
    }
  },
  updateItemAttribute(state,{item, attribute}){
    let updatedItem = state.items.find(i => i.id === item.id)
    if(updatedItem){
      updatedItem.itemTypeData.attributes = attribute
    }
  },
  updateItemPrice(state, {item, price, priceSC}) {
    let updatedItem = state.items.find(i => i.id === item.id)
    if(updatedItem){
      updatedItem.itemTypeData.price = price
      updatedItem.itemTypeData.priceSC = priceSC
    }
  },
  updateItemSelectedQuantity(state, {item, selectedQuantity, selectedQuantity2}){
    let updatedItem = state.items.find(i => i.id === item.id)
    if(updatedItem){
      updatedItem.selectedQuantity = selectedQuantity
      updatedItem.selectedQuantity2 = selectedQuantity2
    }
  },
  updateCartItemQuantities(state, {items}){
    items.forEach(item => {
      let updatedItem = state.items.find(i => i.id === item.id)
      if(updatedItem){
        updatedItem.itemTypeData.quantity = item.selectedQuantity
        updatedItem.itemTypeData.quantity2 = item.selectedQuantity2
      }
    })
  },
}


export const actions = {
  async fetchItemByCode({commit,dispatch, state}, {filters}){
    try{
      let shoppingCartItem = {}

      let result = await this.$axios.get(`webpos/items/get-by-SKU?${new URLSearchParams(filters).toString()}`)
      if(result?.data?.value?.id){
        shoppingCartItem = result?.data?.value

        const itemInCart = state.items.find(x => x.itemTypeData.actualItemID === shoppingCartItem.actualItemID)
        const quantityInCart = itemInCart?.quantity || 0
        shoppingCartItem.totalInventoryQuantity -= quantityInCart
      }
      else{
        const itemTypeResult = await this.$axios.$get(`webpos/itemtypes/get-by-SKU?${new URLSearchParams(filters).toString()}`)
        if(itemTypeResult?.value?.id){
          shoppingCartItem = itemTypeResult?.value
        }
        else{
          this.$toast.error('Item with this code does not exist')
          return;
        }
      }

      dispatch('addToCart', {item: shoppingCartItem})

    }
    catch(e){
      this.$toast.error('Error while getting the item by the scanned code')
    }
  },

  async addMultipleItemsToCart({commit, dispatch, state}, {shoppingCartItems}) {
    let success = true
    try{

      await this.$axios.$post('/webpos/shopping-cart/add-multiple-items-to-cart', shoppingCartItems)
      dispatch('getBySessionId')
      this.$toast.success('Item added successfully')
    }
    catch{
      this.$toast.error('Error while adding item to the cart')
      success = false
    }

    return success
  },
  async addToCart({commit, dispatch, state}, {item}) {
    if(!item) { return }


    if(!item.quantity || (item.itemHasSecondaryMeasureUnit && !item.quantity2)){
      if(item.itemHasSecondaryMeasureUnit){
        item.quantity2 = 1;
        item.quantity = item.itemsUseMUConversions && item.itemsHaveUnitAmount
          ? Number(Number((item.quantity2) * Number(item.defaultUnitAmount > 0 ? item.defaultUnitAmount : 1))
            .toFixed(item.decimalPrecision))
          : 1;
      }
      else{
        item.quantity = 1;
        item.quantity2 = 0;
      }
    }


    if (item.actualItemID > 0){
      if(item.quantity > item.totalInventoryQuantity
        || (item.itemHasSecondaryMeasureUnit && !item.isQuantityBasedOnUnitAmountAndQuantity2 && item.quantity2 > item.totalInventoryQuantity2)){

        this.$toast.error('There is insufficient quantity on inventory')
        return;
      }
    }

    const shoppingCartItem = {
      itemTypeID: item.id,
      notes: item.notes ? item.notes : '',
      code: item.code,
      itemTypeData: {
        itemTypeID: item.id,
        quantity: item.quantity ? item.quantity : 1,
        quantity2: item.quantity2,
        priceType: item.priceType,
        price: item.price ? item.price : 0,
        priceSC: item.priceSC || 0,
        retailPrice: item.retailPrice || 0,
        baseCost: item.cost ? item.cost : 0,
        code: item.code,
        name: item.name,
        groupID: item.groupID,
        notes: item.notes ? item.notes : '',
        attributes: item.attributes || [],
        usePriceFromCartItem: !!item.actualItemID,
        itemSKU: item.itemSKU,
        actualItemID: item.actualItemID ? item.actualItemID : null,
        locationID: item.locationID || null,
        selectedActualItemID: item.selectedActualItemID || null,
        measureUnitSymbol: item.measureUnitSymbol || '',
        decimalPrecision: item.decimalPrecision,
        secondaryMeasureUnitSymbol: item.secondaryMeasureUnitSymbol,
        secondaryMeasureUnitDecimalPrecision: item.secondaryMeasureUnitDecimalPrecision,
        itemHasSecondaryMeasureUnit: item.itemHasSecondaryMeasureUnit,
        itemsUseMUConversions: item.itemsUseMUConversions,
        itemsHaveUnitAmount: item.itemsHaveUnitAmount,
        defaultUnitAmount: item.defaultUnitAmount,
        imageBinaryContentCode: item.imageBinaryContentCode || '',
        totalInventoryQuantity: item.totalInventoryQuantity,
        imageUrl: item.imageBinaryContentCode
          ? this.$config.axios.browserBaseURL + 'images/' + item.imageBinaryContentCode
          : '',
        useItemTypeDataForQuantity: true,
      }
    }

    const addToCartResult = await this.$axios.$post('/webpos/shopping-cart/add-to-cart', shoppingCartItem)
    if (addToCartResult.hasErrors) {
      this.$toast.error('Something went wrong please contact the site administrator')
      return;
    }

    this.$toast.success('Item added successfully!')
    dispatch('getBySessionId')
    return addToCartResult
  },


  async getBySessionId({commit}) {
    const shoppingCart = await this.$axios.$get(
      '/webpos/shopping-cart/get-by-user-id'

    )

    commit('resetItems')
    commit('loadShoppingCart',{shoppingCart})
    commit("updateCartItemCount")
  },

  async updateShoppingCartItemQuantity({commit, dispatch, state}, {modifiedItem}){
    if(modifiedItem.itemTypeData.quantity <= 0 || (modifiedItem.itemTypeData.itemHasSecondaryMeasureUnit && modifiedItem.itemTypeData.quantity2 <= 0)){
      dispatch('deleteCartItem', {item: modifiedItem})
      return
    }

    if (modifiedItem.itemTypeData.actualItemID > 0
      && (modifiedItem.itemTypeData.totalInventoryQuantity < modifiedItem.itemTypeData.quantity
        || (modifiedItem.itemTypeData.itemHasSecondaryMeasureUnit
          && !modifiedItem.itemTypeData.isQuantityBasedOnUnitAmountAndQuantity2
          && modifiedItem.itemTypeData.totalInventoryQuantity2 < modifiedItem.quantity2))){
      this.$toast.error('There is insufficient quantity on inventory');
      return
    }

    const copyOfModifiedItem = JSON.parse(JSON.stringify(modifiedItem))
    commit('updateQuantity', {modifiedItem: copyOfModifiedItem})
    commit("updateCartItemCount")

    let success = true
    try {
      const updateResult = await this.$axios.$post('webpos/shopping-cart/update-cart-item', modifiedItem)

      if (updateResult.hasErrors) {
        this.$toast.error('Something went wrong please contact the site administrator')
        success = false
      }
    }
    catch {
      this.$toast.error('Error while updating the quantity')
      success = false
    }

    if(!success){
      commit('updateQuantity', {modifiedItem: copyOfModifiedItem, useOldQuantity: true})
      commit("updateCartItemCount")
    }

  },

  async updateMultipleQuantity({commit,state,dispatch}, {shoppingCartItems} ){
    try{
       await this.$axios.$put(
        'webpos/shopping-cart/items',
        shoppingCartItems.map(x => {
          let itemForUpdate = {
            id: x.id,
            quantity: x.selectedQuantity,
            itemTypeData: {
              ...x.itemTypeData,
              quantity2: x.selectedQuantity2
            }
          }

          return itemForUpdate
        })
      )

      commit("updateCartItemQuantities", {items: shoppingCartItems})
      commit("updateCartItemCount")
    }
    catch(e){
      this.$toast.error('Error while updating item quantity')
    }
  },

  async deleteCartItem({commit},{item}){
    const updateResult = await this.$axios.$delete(
      `/webpos/shopping-cart/delete-cart-item/${item.id}`
    )
    if(updateResult.hasErrors){
      this.$toast.error('Something went wrong please contact the site administrator')
      return

    }
    commit('removeItem', {item})
    commit("updateCartItemCount")
  },

  async deleteCartItems({commit, state, dispatch}, {itemsForDelete}){

    try{
      await this.$axios.post('webpos/shopping-cart/items', itemsForDelete.map(x => x.id))

      itemsForDelete.forEach(item => { commit('removeItem', {item} ) })
      commit("updateCartItemCount")

    }
    catch(e){
      this.$toast.error('Error while delete item from shopping cart')
    }
  },

  async clearCart({commit, state}){
    if(state.items && state.items.length > 0){
      const clearResult = await this.$axios.$post(
        `/webpos/shopping-cart/clear-cart`
      )
      commit('resetItems')
      commit("updateCartItemCount")

    }
  },

  async updateCartItem({commit,state,dispatch},{item, discount, markupType, attribute, price, priceSC}){
    commit('updateItemDiscount', {item: item, discount: discount, markupType: markupType})
    commit('updateItemAttribute', {item: item, attribute: attribute})
    commit('updateItemPrice', {item, price, priceSC})
    try {
      let shoppingCartItem = state.items.find(i => i.id === item.id)
      if(shoppingCartItem){
        const updateResult = await this.$axios.$post(
          'webpos/shopping-cart/update-cart-item',
          shoppingCartItem
        )
        if (updateResult.hasErrors) {
          this.$toast.error(
            'Something went wrong please contact the site administrator'
          )
        }
        this.$toast.success('Cart item saved successfully')
      }
    }
    catch(e) {
      this.$toast.error('Error while saving the cart item')
    }
  },

  async updateDiscounts({commit, state, dispatch}, {itemsForUpdate, discount, markupType}){

    try{
      await this.$axios.put('webpos/shopping-cart/items', itemsForUpdate)

      itemsForUpdate.forEach(item => {
        commit('updateItemDiscount', {item: item, discount: discount, markupType: markupType})
      })

    }
    catch(e){
      this.$toast.error('Error while updating item discount')
    }
  },


}
