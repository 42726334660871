const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {

}

export const actions = {
  async getEntityStatusesByEntityTypeID({commit, state}, {entityTypeID}) {
    let statuses = []
    try{
      let result = await this.$axios.$get(`webpos/entity-status/get-by-entity-type-id/${entityTypeID}`)
      if(result && !result.hasErrors){
        statuses = result.value;
      }
      else{
        this.$toast.error('Error while getting item groups');
      }
    }
    catch{
      this.$toast.error('Error while fetching entity statuses');
    }
    return statuses;
  }

}
