const initState = () => ({
  newNotificationCount: 0,
})

export const state = initState

export const getters = {
  getNewNotificationCount: (state) => state.newNotificationCount,
}

export const mutations = {
  setNewNotificationCount(state, {newNotificationCount}){
    state.newNotificationCount = newNotificationCount;
  }
}

export const actions = {
  async getNewNotificationCount({commit}) {
    try {
      const result = await this.$axios.$get(`notification/new-notifications-count`);

      if (result && !result.apiMessages.hasErrors) {
        commit('setNewNotificationCount', {newNotificationCount: result.value});
      }
      else{
        this.$toast.error(result.apiMessages.serverErrors[0]);
        commit('setNewNotificationCount', {newNotificationCount: 0});
      }
    }
    catch (e) {
      this.$toast.error("Error while checking for user has notifications!");
      console.error(e)
    }
  },
  async getAllUserNotifications({commit},{pagingData}){
    console.log(pagingData)
    let notifications = {list: [], itemCount: 0}
    try {
      const result = await this.$axios.get(`notification/notification-for-current-user`, {params: pagingData});

      if (result.data &&!result.data.apiMessages.hasErrors) {
        notifications.list = result.data.value;
        notifications.itemCount = result.headers['x-total-count'];
      }
      else{
        this.$toast.error(result.apiMessages.serverErrors[0]);
      }

    }catch (e) {
      console.error(e);
      this.$toast.error("Error while fetching user notifications!");
    }
    return notifications;
  },
  async markNotificationAsRead({commit},{notificationId}){
    try {
      await this.$axios.$post(`notification/${notificationId}/mark-as-read`);
      this.$toast.success("Successfully marked as read");
      return true
    }
    catch (e) {
      console.error(e);
      this.$toast.error("Error while marking notification as read!");
      return false
    }
  }
}
