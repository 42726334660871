const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {

}

export const actions = {
  async getEmailPreview({commit, state}, emailData) {
    let email = null
    try{
      const result = await this.$axios.$get(`/webpos/email/preview?${new URLSearchParams(emailData).toString()}`, emailData);
      if(!result || result.hasErrors){
        this.$toast.error('Error while generating email preview');
      }
      else{
        email = result.value;
      }
    }
    catch{
      this.$toast.error('Error while generating email preview');
    }
    return email;
  },

  async sendEmail({commit, state}, emailPreview) {
    try{
      const result = await this.$axios.$post(`/webpos/email/send`, emailPreview);
      if(result && !result.hasErrors){
        this.$toast.success('Email sent successfully');
        return true;
      }
      else{
        if(result && result.errorMessages && result.errorMessages.length > 0){
          this.$toast.error(result.errorMessages[0].value);
        }
        else{
          this.$toast.error('Error while sending email');
        }
      }
    }
    catch{
      this.$toast.error('Error while sending email');
    }
    return false;
  },

  async sendEmailInstantly({commit, state}, emailData) {
    try{
      const result = await this.$axios.$post(`/webpos/email/send-instantly`, emailData);
      if(result && !result.hasErrors){
        this.$toast.success('Email sent successfully');
        return true;
      }
      else{
        if(result && result.errorMessages && result.errorMessages.length > 0){
          this.$toast.error(result.errorMessages[0].value);
        }
        else{
          this.$toast.error('Error while sending email');
        }
      }
    }
    catch{
      this.$toast.error('Error while sending email');
    }
    return false;
  },

  async getEmailAttachments({commit, state}, emailQueueID){
    let attachments = []
    try{
      const result = await this.$axios.$get(`/webpos/email/attachments?emailQueueID=${emailQueueID}`);
      if (result && !result.apiMessages.hasErrors) {
        attachments = result.value;
      }
      else{
        this.$toast.error("Error while getting customer email");
      }
    }
    catch(e){
      this.$toast.error('Error while getting attachments');
    }
    return attachments;
  }
}
