
const initState = () => ({
  wishlistItems: []
})

export const state = initState

export const getters = {

}

export const mutations = {

  setWishlist(state, {wishlist}){
    state.wishlistItems = wishlist
  },
  resetWishlist(state){
    state.wishlistItems = []
  },
  loadMore(state, {wishlist}){
    wishlist.forEach(x => state.wishlistItems.push(x))
  }
}

export const actions = {
  async getCustomerWishlistByID({commit}, {filter, calculateQuantities}){

    try{
      const result =  await this.$axios.$get(`/webpos/wishlist/items?${new URLSearchParams(filter).toString()}`)

      if(result?.apiMessages?.hasErrors){
        this.$toast.error('Error while getting customer wishlist');
      }
      else{
        if(result.value?.length > 0){
          result.value.forEach(wishlistItem => {
            if(wishlistItem.actualItemID){
              calculateQuantities(wishlistItem)
            }
          })

          if (filter.pageSize > 0){
            commit('loadMore', {wishlist: result.value})
          }
          else {
            commit('setWishlist', {wishlist: result.value})
          }
        }
      }
    }
    catch (e){
      if(e.response.status === 404){
        this.$toast.error('The selected customer does not exist');
      }
      this.$toast.error('Error while getting customer wishlist');
    }

  },
  async deleteWishlistItem({commit}, itemID){
    let success = true;
    try{
      await this.$axios.$delete(`/webpos/wishlist/items/${itemID}`)
      this.$toast.success('Wishlist item successfully deleted');
    }
    catch(e){
      this.$toast.error('Error while deleting wishlist item');
      success = false;
    }
    return success;
  },

  async addItemToWishlist({}, {wishlistItem, customerID}){
    let success = true
    try {
      await this.$axios.post(`webpos/customer/${customerID}/wishlist/add-item`, wishlistItem)
      this.$toast.success('Item successfully added to wishlist!');
    }
    catch (e){
      success = false;
      if(e.response.status === 404){
        this.$toast.error('The selected customer does not exist');
      }
      this.$toast.error('Error while adding item to wishlist');
    }

    return success
  },

  async getWishlistItemByID({commit}, {wishlistItemID}){
    let itemType = null;
    try{
      const result = await  this.$axios.$get(`/webpos/wishlist/items/${wishlistItemID}`);

      if(result && !result.hasErrors){
        itemType = result.value;
      }

      else{
        this.$toast.error('Error while getting wishlist item');
      }
    }
    catch(e){
      this.$toast.error('Error while getting wishlist item');
    }

    return itemType;
  },


}
