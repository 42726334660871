import {formActions} from "@/enums/formActions";
import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  creditCards: [],
  creditMemos: [],
  payments: {
    items: [],
    itemCount: null
  },
  paymentProcessor:{
    paymentGatewayPartnerName: null,
  },
})

export const state = initState

export const mutations = {
  setCreditCards(state,{creditCards}){
    state.creditCards = creditCards
  },
  setPayments(state, {payments}) {
    state.payments = payments
  },
  setCustomerCredits(state, {customerCredits}) {
    state.customerCredits = customerCredits
  },
  setPaymentProcessor(state, {paymentProcessor}){
    state.paymentProcessor = paymentProcessor;
  }
}

export const getters = {
  getPaymentGatewayPartnerName(state){
    return state.paymentProcessor.paymentGatewayPartnerName;
  },
  getPaymentProcessorConfiguration(state){
    return state.paymentProcessor;
  },
  getPaymentProcessorGatewayId(state){
    return state.paymentProcessor.defaultPaymentGatewayId;
  }
}

export const actions = {
  async postPayment({commit}, {paymentData}) {
    let paymentResult = null
    try{
      const url = `/webpos/payments/${paymentData.invoiceID ? 'save-payment' : 'save-overpayment'}`
      paymentResult = await this.$axios.$post(url, paymentData)
    }
    catch{
      this.$toast.error('An error occurred while processing the payment.')
    }
    return paymentResult
  },
  fetchCreditCards({commit}, {customerID, gatewayId}) {
      return this.$axios.$get(`/webpos/customer/${customerID}/credit-cards/gateway/${gatewayId}`).then((creditCards) => {
        commit('setCreditCards', {creditCards})
        return creditCards
      })
  },
  async getPayments({commit, dispatch}, {filters, pagingInfo}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/payments/get-payments`
          let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);

          let response = await this.$axios.$post(url,filter)

          let payments = await dispatch('mapPayments', {response: response})
          commit('setPayments', {payments: payments})
          resolve(payments)
        },
        formActions.Load,
        'payments'
      )
    })
  },
    mapPayments({commit},{response}){
      let payments = {
        items: [],
        itemCount: null
      }
      payments.items = response.items
      payments.itemCount = response.pagingData.itemCount
      return payments
  },
  fetchCustomerCredits({commit}, customerID) {
    const customerCredits = this.$axios.$get(`/webpos/customer-credit/${customerID}`)
    commit('setCustomerCredits', {customerCredits})

    return customerCredits
  },
  async createTerminalCheckout({commit},terminalCheckoutVm){
    try {
      const checkoutResult = await this.$axios.$post('/webpos/payments/create-checkout',terminalCheckoutVm);
      if (checkoutResult && checkoutResult.apiMessages.hasErrors)
      {
        this.$toast.error(checkoutResult.apiMessages.serverErrors[0]);
        return null
      }
      else
      {
        this.$toast.success("Checkout created successfully!");
        return checkoutResult.value ?? true; //for cases where the checkout was successfully created but no response was received
      }
    }catch (error) {
      if (error.response) {
        this.$toast.error(error.response.data.Message);
      }  else {
        this.$toast.error(error);
      }
      return null
    }
  },
  async cancelSquareCheckout({commit},checkoutId) {
    try {
      const checkoutResult = await this.$axios.$post(`/webpos/payments/cancel-checkout/${checkoutId}`);
      if (checkoutResult && checkoutResult.apiMessages.hasErrors) {
        this.$toast.error(checkoutResult.apiMessages.serverErrors[0]);
        return null
      } else {
        this.$toast.success("Checkout cancelled successfully!");
        return checkoutResult.value;
      }
    } catch (error) {
      if (error.response) {
        this.$toast.error(error.response.data.Message);
      } else {
        this.$toast.error(error);
      }
      return null
    }
  },
  async refundPayment({commit}, refundVm){
    try {
      const refundResult = await this.$axios.$post(`/webpos/payments/refund-payment`, refundVm);

      if (refundResult && refundResult.apiMessages.hasErrors) {
        this.$toast.error(refundResult.apiMessages.serverErrors[0]);
        return null
      } else {
        this.$toast.success("Payment refunded successfully!");
        return refundResult.value;
      }
    }catch (error) {
      if (error.response && error.response.data.Message) {
        this.$toast.error(error.response.data.Message);
      } else {
        this.$toast.error(error);
      }
      return null
    }
  },
  async fetchPaymentProcessor({commit}){
    try {
      let result = await this.$axios.get(`webpos/payments/get-payment-processor`);
      if (result?.data?.apiMessages?.hasErrors && result.apiMessages.serverErrors.length > 0) {
        this.$toast.error('Error while getting payment processor');
      }
      else {
        commit('setPaymentProcessor', {paymentProcessor: result.data.value})
        return result.data.value
      }
    }catch (e) {
      if (e.response) {
        this.$toast.error(e.response.data.Message);
      }  else {
        this.$toast.error(e);
      }
    }
    return null;
  },
  async getTerminals({commit},  {paymentProcessorId}) {
    try {
      let terminals = await this.$axios.$get(`/webpos/payments/get-terminals/${paymentProcessorId}`)
      if (terminals && terminals.hasErrors)
      {
        this.$toast.error(terminals.errorMessages[0]);
        return []
      }

      //Remove duplicate deviceIds
      return terminals.value.reduce((acc, current) => {
        const x = acc.find(item => item.deviceId === current.deviceId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        console.error(ex);
        this.$toast.error("Error occurred while getting terminals")
        return []
      }
    }
  },
}
