import * as signalR from "@microsoft/signalr";

export default ({ app }, inject) => {
  const defaultAPI = 'http://localhost:50000/api/'
  const apiBaseUrl = app.$config.axios.browserBaseURL || defaultAPI
  const connection = new signalR.HubConnectionBuilder()
    .withUrl( `${apiBaseUrl}signalr`,{skipNegotiation: true,transport: signalR.HttpTransportType.WebSockets}) // The URL where your SignalR hub is hosted
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.start().then(() => {
    console.log("Connection established");
    if (connection && connection.state === "Connected" && app.$auth?.user?.id) {
      try {
        connection.invoke("AddToGroupAsync", app.$auth.user.id)
        console.log("Joined group successfully");
      } catch (error) {
        console.error("Error joining group:", error);
      }
    } else {
      console.error("SignalR connection is not established");
    }

    connection.on("sendnotificationtouser", (user, message) => {
      if (message && message.data) {
        app.$toast.success(message.data.message)
        app.store.dispatch('common/notification/getNewNotificationCount');
      } else {
        console.error("SignalR message is missing!.");
        app.$toast.error("SignalR message is missing!.")

      }
    });
  }).catch(err => console.error(err));


  inject('connection', connection);
}
