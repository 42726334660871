import {formActions} from '@/enums/formActions'

const initState = () => ({
  metals: [],
  itemTypes: [],
  attributes: [],
  metalSubItemVariants: [],
  ringSizeDialogShown: false,
})

export const state = initState

export const getters = {
  getMetals: (state) => state.metals,
  getItemTypes: (state) => state.itemTypes,
  getAttributes: (state) => state.attributes,
}

export const mutations = {
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setItemTypes(state, {itemTypes}) {
    state.itemTypes = itemTypes
  },
  setAttributes(state, {attributes}) {
    state.attributes = attributes
  },
  setMetalSubItemVariants(state, {metalSubItemVariants}) {
    state.metalSubItemVariants = metalSubItemVariants
  },
  setRingSizeDialogShown(state, ringSizeDialogShown) {
    state.ringSizeDialogShown = ringSizeDialogShown
  },
}

export const actions = {
  fetchMetals({commit}) {
    return this.$axios.$get('/Metals/ShownInCatalog').then((metals) => {
      commit('setMetals', {
        metals: metals.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
  async fetchItemTypes({commit}) {
    return await this.$axios.$get('/ItemTypes/ShownInCatalog').then((itemTypes) => {
      commit('setItemTypes', {
        itemTypes: itemTypes.map((element) => {
          return {text: element.name, code: element.code, value: element.id}
        }),
      })
    })
  },

  async fetchItemTypeByCode({commit}, {filters}){
    let result;
    try{
      const itemTypeResult = await this.$axios.$get(`webpos/itemtypes/get-by-SKU?${new URLSearchParams(filters).toString()}`)
      if(itemTypeResult?.value?.id > 0){
        result = itemTypeResult?.value
      }
      else{
        this.$toast.error('Error while getting the item type');
      }
    }
    catch(e){
      this.$toast.error('Error while getting the item type');
    }

    return result
  },

  async fetchMetalSubItemVariants({commit}, itemtypeId) {
    await this.$catch(
      async () => {
        const metalSubItemVariants = await this.$axios.$get(
          `/Metals/getMetalSubItemVariants/${itemtypeId}`
        )
        commit('setMetalSubItemVariants', {
          metalSubItemVariants: metalSubItemVariants.map((x) => {
            return {text: x.name, value: x.id}
          }),
        })
      },
      formActions.Load,
      'Metal variants'
    )
  },
  updateAttributes({commit}, attributes) {
    commit('setAttributes', {attributes})
  },

  async getItemTypesForFilter({commit}, {filters}){
    let result ={list: [] , itemCount: 0};
    try{
      let paginatedResult = await this.$axios.get('/webpos/itemTypes/get-for-filter', {params: filters})

      if(paginatedResult && !paginatedResult.data.apiMessages.hasErrors && paginatedResult.data.value) {
        result.list = paginatedResult.data.value.map((itemType) => {
          return {
            text: itemType.text, value: itemType.value
          };
        });
        result.itemCount = paginatedResult.headers['x-total-count'];
      } else{
        this.$toast.error('Error while getting item types');
      }
    }catch(e){
      this.$toast.error('Error while getting item type');
    }
    return result;
  },

  async getItemTypeByID({commit}, {itemTypeID, additionalInfo}){
    let itemType = null;
    try{
      const result = await  this.$axios.$post(
        `/webpos/itemtypes/${itemTypeID}`,
        additionalInfo
      );

      if(result && !result.hasErrors){
        itemType = result.value;
      }

      else{
        this.$toast.error('Error while getting item type');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item type');
    }

    return itemType;
  },

  async getItemTypes({commit}, itemTypeFilter){
    let paginatedItemTypes = [];
    try{
      const result = await  this.$axios.post(`/webpos/itemtypes`, itemTypeFilter);
      if(result && !result.data.hasErrors && result.data.value){
        paginatedItemTypes.list = result.data.value;
        paginatedItemTypes.itemCount = result.headers['x-total-count'];
      }
      else{
        this.$toast.error('Error while getting item types');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item types');
    }
    return paginatedItemTypes;
  },

  async getCustomAttributesByItemTypeID ({commit}, {id, filter}){
    let customAttributes = [];
    try{
      const result = await  this.$axios.$get(`/webpos/itemtypes/${id}/custom-attributes?${new URLSearchParams(filter).toString()}`);
      if(result && !result.hasErrors){
        customAttributes = result.value;
      }
      else{
        this.$toast.error('Error while getting custom attributes for item type');
      }
    }
    catch(e){
      this.$toast.error('Error while getting custom attributes for item type');
    }

    return customAttributes;
  },

  async getItemTypeListItemsForCombo({commit}, {filters}){
    let paginatedItemTypes = [];
    try{
      const result = await  this.$axios.get(`/webpos/itemtypes/items-for-combo`, {params: filters});
      if(result && !result.data.hasErrors && result.data.value){
        paginatedItemTypes.list = result.data.value;
        paginatedItemTypes.itemCount = result.headers['x-total-count'];
      }
      else{
        this.$toast.error('Error while getting item types by item group id');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item types by item group id');
    }
    return paginatedItemTypes;
  },

  async getItemTypePrice({commit}, {id, priceSettings})
  {
    let priceResult = { price: 0, priceSC: 0 }
    try{
      const result = await this.$axios.$post(`/webpos/itemtypes/${id}/price`, priceSettings);
      priceResult = result.value
    }
    catch{
      this.$toast.error('Error while getting item type price');
    }
    return priceResult;
  }
}
