//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

  props: {
    text: {
      required: true,
      type: "",
    },
    disabled: {
      required: false,
      type: Boolean
    },
    withBackground:{
      required: false,
      type: Boolean
    },
    icon:{
      required: false,
      type: String,
    },
    appendIcon:{
      required: false,
      type: String,
    }
  },
  name: "primary-button",
}
